<template>
  <div>
    <vx-card :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Nomor</label>
          <vs-input class="w-full" v-model="data.no_keamanan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <vs-input class="w-full" v-model="data.nama_proyek" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Lokasi</label>
          <vs-input class="w-full" v-model="data.lokasi" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Mulai</label>
          <vs-input class="w-full" v-model="data.tgl_mulai" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Selesai</label>
          <vs-input class="w-full" v-model="data.tgl_akhir" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Foto</label>
          <img v-if="data.foto" :src="data.foto_url_list[0]" @click="showImages(data.foto_url_list)" class="w-8 h-auto" alt="photos"/>
          <span class="text-sm" v-else>Tidak ada foto</span>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Keterangan</label>
          <vs-textarea class="w-full" v-model="data.keterangan" readonly/>
        </div>
      </div>

      <vs-divider> Monitoring Asset </vs-divider>

      <div class="overflow-auto">
        <table class="table-fixed border-collapse w-full">
          <tr>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-10">#</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-64">Nama Asset</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-16">Jml</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-48">Rusak</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Foto</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-16">Urgent</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-64">Keterangan</th>
          </tr>
          <tr v-for="(item, index) in data.details" :key="index" class="text-sm">
            <td class="p-2 border border-solid d-theme-border-grey-light text-right">{{ index + 1 }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.nama_asset }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.jml_asset }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.jml_rusak }}</td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">
              <img v-if="item.foto" :src="item.foto_url_list[0]" @click="showImages(item.foto_url_list)" class="w-8 h-auto" alt="photos"/>
              <span v-else>-</span>
            </td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left"><vs-checkbox class="justify-center" v-model="item.is_urgent" disabled/></td>
            <td class="p-2 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan }}</td>
          </tr>
          <!--empty state-->
          <tr v-if="data.details.length < 1">
            <td colspan="7" class="text-sm text-center p-4">Tidak ada data.</td>
          </tr>
        </table>
      </div>
    </vx-card>

    <!--modals-->
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import KeamananRepository from '@/repositories/estate/keamanan-repository'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import 'flatpickr/dist/flatpickr.css'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'KeamananShow',
  components: {
    ImageViewer
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      data: {
        details: []
      }
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idKeamanan = this.$route.params.idKeamanan
      KeamananRepository.show(idKeamanan)
        .then(response => {
          const data = response.data.data
          data.details = _.map(data.details, item => {
            item.uuid = uuid()
            return item
          })
          this.data = data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    }
  }
}
</script>
